import React, { useRef, useState } from "react";
import EmailEditor from "react-email-editor";
import { Button, Container, Typography, Box } from "@mui/material";

const EmailBuilder = () => {
    const editorRef = useRef(null);
    const [htmlContent, setHtmlContent] = useState("");

    // ✅ Export Full HTML Including Headers & Footers
    const exportHtml = () => {
        editorRef.current?.editor.exportHtml((data) => {
            const fullHtml = `
                <!DOCTYPE html>
                <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>Email Template</title>
                    <style>
                        body { font-family: Arial, sans-serif; margin: 0; padding: 0; }
                        .container { max-width: 600px; margin: auto; padding: 20px; }
                    </style>
                </head>
                <body>
                    <div class="container">
                        ${data.html} <!-- Ensure all content is captured -->
                    </div>
                </body>
                </html>
            `;
            setHtmlContent(fullHtml);
        });
    };

    // ✅ Copy HTML to Clipboard
    const copyHtml = () => {
        navigator.clipboard.writeText(htmlContent);
        alert("HTML copied to clipboard!");
    };

    // ✅ Download Full HTML with Styles
    const downloadHtml = () => {
        const blob = new Blob([htmlContent], { type: "text/html" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "email_template.html";
        link.click();
    };

    // ✅ Send Email via API
    const sendEmail = async () => {
        try {
            await fetch("https://your-backend-api.com/send-email", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    recipient: "test@example.com",
                    subject: "Custom Email",
                    content: htmlContent,
                }),
            });
            alert("Email sent successfully!");
        } catch (error) {
            alert("Error sending email!");
            console.error(error);
        }
    };

    return (
        <Container maxWidth="lg" sx={{ py: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Email Builder
            </Typography>

            {/* Unlayer Email Editor */}
                        <Box
                            sx={{
                               
                                // top: 0,
                                // left: 0,
                                // width: "100%",
                                overflow: "hidden",
                            }}
                            
                        >
                            <EmailEditor ref={editorRef} onLoad={() => console.log("Editor Loaded")} 
                               />
                        </Box>

                        {/* Export & Actions */}
            <Box sx={{ display: "flex", gap: 2 }}>
                <Button variant="contained" color="primary" onClick={exportHtml}>
                    Export HTML
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={copyHtml}
                    disabled={!htmlContent}
                >
                    Copy HTML
                </Button>
                <Button
                    variant="contained"
                    color="warning"
                    onClick={downloadHtml}
                    disabled={!htmlContent}
                >
                    Download HTML
                </Button>
                <Button
                    variant="contained"
                    color="success"
                    onClick={sendEmail}
                    disabled={!htmlContent}
                >
                    Send Email
                </Button>
            </Box>
        </Container>
    );
};

export default EmailBuilder;
